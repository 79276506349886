import React, { useEffect, useRef, useState } from "react";
import {
    Avatar,
    Box,
    Collapse,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { i18n } from "../../translate/i18n";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import api from "../../services/api";
import TransferTicketModal from "../../components/TransferTicketModal";
import SpyTicketModal from "../../components/SpyTicketModal";

const TicketsPerUser = () => {
    const [ticketsUser, setTicketUsers] = useState([]);
    const fetchMetrics = async () => {
        const { data } = await api.get("/dashboard/metrics/ticketUser");
        setTicketUsers(data);
    }
    useEffect(() => {
        fetchMetrics();
    }, []);
    return (
        <TableContainer>
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="left">{i18n.t("dashboard.ticketsPendingByUser.table.user")}</TableCell>
                        <TableCell align="left">{i18n.t("dashboard.ticketsPendingByUser.table.ticketAmount")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                        {ticketsUser.map((ticketUser, index) => (
                            <Row key={index} row={ticketUser} onClose={fetchMetrics} />
                        ))}
                    </>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const Time = (props) => {
    const { timeInMinutes } = props;
    const getYears = timeInMinutes / 525600;
    const getMonths = timeInMinutes / 43800;
    const getDays = timeInMinutes / 1440;
    const getHours = timeInMinutes / 60;
    const isYear = Math.trunc(getYears) > 0;
    const isMonth = Math.trunc(getMonths) > 0;
    const isDays = Math.trunc(getDays) > 0;
    const isHours = Math.trunc(getHours) > 0;
    const getBackgroudColor = () => {
        if (isYear || isMonth || isDays) return "#f44336";
        if (getHours > 3) return "rgb(225 199 89)";
        return "rgb(112 255 182 / 72%)";
    };
    const renderTime = () => {
        // TODO: implement internacionalization for description time
        if (isYear) return `${getYears.toFixed(0)} ${Math.trunc(getYears) > 1 ? "anos" : "ano"}`;
        if (isMonth) return `${getMonths.toFixed(0)} ${Math.trunc(getMonths) > 1 ? "meses" : "mês"}`;
        if (isDays) return `${getDays.toFixed(0)} ${Math.trunc(getDays) > 1 ? "dias" : "dia"}`;
        if (isHours) return `${getHours.toFixed(0)} ${Math.trunc(getHours) > 1 ? "horas" : "hora"}`;
        return `${timeInMinutes.toFixed(0)} ${Math.trunc(timeInMinutes) > 0 ? "minutos" : "minuto"}`;
    };
    return (
        <>
            <Container style={{ borderRadius: "3px", padding: 8, textAlign: "center", backgroundColor: getBackgroudColor() }}>
                <div style={{ fontWeight: 600, textAlign: "center" }}>
                    {renderTime()}
                </div>
            </Container>
        </>
    );
}

const Row = (props) => {
    const { row, onClose } = props;
    const [open, setOpen] = useState(false);
    const [menuAnchorEls, setMenuAnchorEls] = useState({});
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const isMounted = useRef(true);
    const [openSpy, setOpenSpy] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const handleCollapse = () => {
        setOpen(!open);
    };
    const handleOpenMenu = (event, ticketId) => {
        setMenuAnchorEls((prev) => ({ ...prev, [ticketId]: event.currentTarget }));
    }
    const handleCloseMenu = (ticketId) => {
        setMenuAnchorEls((prev) => ({ ...prev, [ticketId]: null }));
    };
    const handleSpy = (ticket) => {
        handleCloseMenu(ticket.id);
        setSelectedTicket(ticket);
        setOpenSpy(true);
    };
    const handleOpenTransferModal = (ticket) => {
        handleCloseMenu(ticket.id);
        setSelectedTicket(ticket);
        setTransferTicketModalOpen(true);
    };
    const handleCloseTransferTicketModal = () => {
        setSelectedTicket(null);
        if (isMounted.current) {
            setTransferTicketModalOpen(false);
            onClose();
        }
    };
    const handleCloseSpy = () => {
        setSelectedTicket(null);
        setOpenSpy(false);
    };
    return (
        <>
            <TransferTicketModal
                modalOpen={transferTicketModalOpen}
                onClose={handleCloseTransferTicketModal}
                ticketid={selectedTicket?.id}
                ticketWhatsappId={selectedTicket?.whatsappId}
                redirect={false}
            />
            <SpyTicketModal
                modalOpen={openSpy}
                onClose={handleCloseSpy}
                companyId={selectedTicket?.companyId}
                ticketId={selectedTicket?.id}
                isGroup={selectedTicket?.isGroup}
            />
            <TableRow>
                <TableCell style={{ width: "5%" }}>
                    {row.tickets.length > 0 && <IconButton aria-label="expand row" size="small" onClick={handleCollapse}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell component="th" scope="row">{row.ticketAmount}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography component="h3" variant="h6" color="primary" paragraph>
                                {i18n.t("mainDrawer.listItems.tickets")}
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >{i18n.t("dashboard.ticketsPendingByUser.table.time")}</TableCell>
                                        <TableCell style={{ width: "30%" }}>{i18n.t("dashboard.ticketsPendingByUser.table.user")}</TableCell>
                                        <TableCell style={{ minWidth: "min-content" }}>{i18n.t("dashboard.ticketsPendingByUser.table.actions")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {row.tickets.map((ticket, index) => (
                                            <TableRow key={ticket.id}>
                                                <TableCell style={{ width: "10%" }}>
                                                    <Time timeInMinutes={ticket.timeInMinutes} />
                                                </TableCell>
                                                <TableCell style={{ display: "flex", padding: 10, alignItems: "center" }}>
                                                    <Avatar src={ticket?.contact?.profilePicUrl} />
                                                    <div style={{ paddingLeft: 10 }}>
                                                        {ticket.contact.name} -
                                                        {ticket.id}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls="options"
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleOpenMenu(event, ticket.id)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="options"
                                                        anchorEl={menuAnchorEls[ticket.id] || null}
                                                        keepMounted
                                                        open={Boolean(menuAnchorEls[ticket.id])}
                                                        onClose={() => handleCloseMenu(ticket.id)}
                                                    >
                                                        <MenuItem 
                                                            onClick={() => handleSpy(ticket)}
                                                        >
                                                            {i18n.t("dashboard.ticketsPendingByUser.table.buttons.spy")}
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => handleOpenTransferModal(ticket)}
                                                        >
                                                            {i18n.t("dashboard.ticketsPendingByUser.table.buttons.transfer")}
                                                        </MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default TicketsPerUser;