import { useState, useEffect, useReducer } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";
import connectToSocket from "../../services/socket-io";
import { getWhatsappEvent, getWhatsappSessionEvent } from "../../utils/constants";
import useCompany from "../../hooks/useCompany";

const reducer = (state, action) => {
	if (action.type === "LOAD_WHATSAPPS") {
		const whatsApps = action.payload;
		return [...whatsApps];
	}
	if (action.type === "UPDATE_WHATSAPPS") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);
		if (whatsAppIndex !== -1) {
			state.splice(whatsAppIndex, 1, whatsApp);
			return [...state];
		} else {
			return [whatsApp, ...state];
		}
	}
	if (action.type === "UPDATE_SESSION") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);
		if (whatsAppIndex !== -1) {
			state.splice(whatsAppIndex, 1, whatsApp);
		}
		return [...state];
	}
	if (action.type === "DELETE_WHATSAPPS") {
		const whatsAppId = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === whatsAppId);
		if (whatsAppIndex !== -1) {
			state.splice(whatsAppIndex, 1);
		}
		return [...state];
	}
	if (action.type === "RESET") {
		return [];
	}
};

const useWhatsApps = () => {
	const companyId = useCompany();
	const [whatsApps, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(true);
	const fetchSession = async () => {
		setLoading(true);
		try {
			const { data } = await api.get("/whatsapp/");
			dispatch({ type: "LOAD_WHATSAPPS", payload: data });
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};
	useEffect(() => {
		fetchSession();
	}, []);
	useEffect(() => {
		if (!companyId) return;
		const socket = connectToSocket();
		socket.on(getWhatsappEvent(companyId), data => {
			if (data.action === "update") {
				dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
			}
			if (data.action === "delete") {
				dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
			}
		});
		socket.on(getWhatsappSessionEvent(companyId), data => {
			if (data.action === "update") {
				dispatch({ type: "UPDATE_SESSION", payload: data.session });
			}
		});
		return () => {
			socket.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);
	return { whatsApps, loading };
};

export default useWhatsApps;
