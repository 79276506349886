import { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer";
import ConfirmationModal from "../../components/ConfirmationModal";
import Title from "../../components/Title";
import MainHeader from "../../components/MainHeader";
import TableRowSkeleton from "../../components/TableRowSkeleton"
import toastError from "../../errors/toastError"
import api from "../../services/api"
import { i18n } from "../../translate/i18n";
import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { parseISO } from "date-fns";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        background: theme.palette.background.default
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tableRow: {
        background: theme.palette.background.inverse
    }
}));

const Invoices = () => {
    const classes = useStyles();
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);    
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);        
    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedInvoice(null);
    };
    const handlePayInvoice = async () => {
        console.log("paid");
    }
    const fetchInvoices = async () => {
        setLoading(true);
        try {
            const { data: { invoices } } = await api.get("/invoices");
            setInvoices(invoices);
        } catch (err) {
            toastError(err);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchInvoices();
    }, []);
    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    selectedInvoice &&
                    `${i18n.t("invoices.confirmationModal.payTitle")} ${i18n.t("currencyFormat", { value: selectedInvoice.value })}?`
                }
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handlePayInvoice(selectedInvoice.id)}
            >
                
            </ConfirmationModal>            
            <MainHeader>
                <Title>{i18n.t("invoices.title")}</Title>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("invoices.table.detail")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("invoices.table.value")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("invoices.table.createAt")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("invoices.table.dueDate")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("invoices.table.status")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("invoices.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {invoices.map(invoice => (
                                <TableRow key={invoice.id}>
                                    <TableCell align="center">{invoice.detail}</TableCell>
                                    <TableCell align="center">{i18n.t("currencyFormat", { value: invoice.value })}</TableCell>
                                    <TableCell align="center">{i18n.t("dateFormat", { date: parseISO(invoice.createdAt) })}</TableCell>
                                    <TableCell align="center">{i18n.t("dateFormat", { date: parseISO(invoice.dueDate) })}</TableCell>
                                    <TableCell align="center">{invoice.status}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setSelectedInvoice(invoice);
                                                setConfirmModalOpen(true);
                                            }}
                                        >
                                            {i18n.t("invoices.buttons.pay")}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Invoices;