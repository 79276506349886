import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import toastError from "../../errors/toastError";
import { Dialog, DialogContent, makeStyles, Paper, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import connectToSocket from "../../services/socket-io";
import { getWhatsappSessionEvent } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
	qrCode: {
		backgroundColor: "#ffffff",
		padding: 10
	}
}));

const QrcodeModal = ({ open, onClose, companyId, whatsAppId }) => {
	const classes = useStyles();
	const [qrCode, setQrCode] = useState("");

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;
			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.qrcode);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [whatsAppId]);

	useEffect(() => {
		if (!whatsAppId) return;
		const socket = connectToSocket();
		socket.on(getWhatsappSessionEvent(companyId), data => {
			if (data.action === "update" && data.session.id === whatsAppId) {
				setQrCode(data.session.qrcode);
			}
			if (data.action === "update" && data.session.qrcode === "") {
				onClose();
			}
		});
		return () => {
			socket.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [whatsAppId, onClose]);
	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
			<DialogContent>
				<Paper elevation={0}>
					<Typography color="primary" gutterBottom>
						{i18n.t("qrCode.message")}
					</Typography>
					{qrCode ? (
						<div className={classes.qrCode}>
							<QRCode value={qrCode} size={256} />
						</div>
					) : (
						<span>Waiting for QR Code</span>
					)}
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
