import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import { makeStyles, Paper, Table, TableBody, 
    TableCell, 
    TableContainer, 
    TableFooter, 
    TableHead, 
    TablePagination, 
    TableRow, 
    Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";

const useStyles = makeStyles(theme => ({
    containerTitle: {
		padding: theme.spacing(2),		
	}
}));

const ContactRanking = () => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [count, setCount] = useState(0);    
    const [contactRanking, setContactRanking] = useState([]);
    const fetchContacts = async () => {
        if (loading) return;
 		const url = `/dashboard/metrics/getcontactranking?limit=5&offset=${offset}`;
        setLoading(true);
        try {
            const { data } = await api.get(url);
            const { ranking, count } = data;
            setContactRanking(ranking);
            setCount(parseInt(count));
            setLoading(false);
        } catch (error) {
            toastError(error);
        }
	}
    const handleNextPage = (_, newPage) => {
        setCurrentPage(newPage);
        const newOffset = 5 * newPage;
        setOffset(newOffset);
    };
    useEffect(() => {
        fetchContacts();
        // eslint-disable-next-line
    }, [offset]);
    return (        
        <TableContainer component={Paper}>							
            <div className={classes.containerTitle}>
                <Typography component="h3" variant="h6" color="primary" paragraph>
                    {i18n.t("dashboard.messages.rankingPerContact.title")}
                </Typography>
            </div>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{i18n.t("mainDrawer.listItems.tickets")}</TableCell>
                        <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>                    
                        {contactRanking.map((metric, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {metric.amount}
                                </TableCell>
                                <TableCell>
                                    {metric.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </>
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5]}
                            colSpan={3}
                            count={count}
                            rowsPerPage={5}
                            page={currentPage}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleNextPage}
                            ActionsComponent={TablePaginationActions}
                        />										
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default ContactRanking;