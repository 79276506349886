import { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer";
import api from "../../services/api";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";

const Plans = () => {
    const [plans, setPlans] = useState([]);
    const fetchPlans = async () => {
        const { data: { plans } } = await api.get("/plans");        
        setPlans(plans);
    };
    useEffect(() => {
        fetchPlans();
    }, []);
    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("plans.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { }}
                    >
                        {i18n.t("plans.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("plans.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.users")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.connections")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.queues")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.price")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {plans.map(plan => (
                                <TableRow key={plan.id}>
                                    <TableCell align="center">{plan.name}</TableCell>
                                    <TableCell align="center">{plan.users}</TableCell>
                                    <TableCell align="center">{plan.connections}</TableCell>
                                    <TableCell align="center">{plan.queues}</TableCell>
                                    <TableCell align="center">{i18n.t("currencyFormat", { value: plan.value })}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => { }}
                                        >
                                            <Edit />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                // setSelectedQueue(queue);
                                                // setConfirmModalOpen(true);
                                            }}
                                        >
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Plans;