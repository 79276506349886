import React, { createContext } from "react";
import { createTheme, ThemeProvider as ReactThemeProvider } from "@material-ui/core";
import useTheme from "../../hooks/useTheme";

const ThemeContext = createContext();

const lightTheme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        type: "light",
        primary: { main: "#2576d2" },
        background: {
          default: "#eee",
          inverse: "#ffffff",
          messageCard: "#dcf8c6",
          messageQuotedCardRight: "#cfe9ba",
          messageQuotedCardLeft: "#dddddd"
        },
        text: {
          color: "#303030"
        }
      },
    },  
  );
  
  const darkTheme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        type: "dark",
        primary: { main: "#2576d2" },
        background: {
          default: "#303030",
          inverse: "#424242",
          messageCard: "#075e54",
          messageQuotedCardRight: "#065048",
          messageQuotedCardLeft: "#424242",
        },
        text: {
          color: "#ffffff"  
        }
      },
    },  
  );

const ThemeProvider = ({ locale, children }) => {    
    const { isDarkMode, handleChangeTheme } = useTheme();
    return (
        <ThemeContext.Provider
            value={{ isDarkMode, handleChangeTheme }}
        >
            <ReactThemeProvider 
                theme={isDarkMode? {...darkTheme, locale} : {...lightTheme, locale}}             
            >
                { children }
            </ReactThemeProvider>
        </ThemeContext.Provider>
    );
}

export { ThemeContext, ThemeProvider }