import { Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { i18n } from "../../translate/i18n";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import MessagesList from "../MessagesList";

const SpyTicketModal = ({ modalOpen, onClose, companyId, ticketId, isGroup }) => {    
    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={modalOpen}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle id="confirm-dialog">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {i18n.t("dashboard.ticketsPendingByUser.messages")}
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <ReplyMessageProvider>
                    <MessagesList
                        companyId={companyId}
                        ticketId={ticketId}
                        isGroup={isGroup}
                    />
                </ReplyMessageProvider>
            </DialogContent>
        </Dialog>
    );
};

export default SpyTicketModal;