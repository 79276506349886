import { StatusTicket } from "../utils/constants";

const ShouldUpdateTicket = (ticket, user, searchParam, showAll, selectedQueueIds) => {	
	// return !searchParam &&
	// 	(!ticket.userId || ticket.userId === user.id || showAll) &&
	// 	(!ticket.queueId || selectedQueueIds.includes(ticket.queueId) === -1)
	if (searchParam) return false;
	if (showAll) return true;
	if (ticket.status === StatusTicket.OPEN && ticket.createByMe) return true;
	const isTicketUnassigned = !ticket.userId;
	const isTicketAssignedToCurrentUser = ticket.userId === user?.id;
	if (!isTicketUnassigned && !isTicketAssignedToCurrentUser) return false;
	const isTicketInSelectedQueues = selectedQueueIds.includes(ticket.queueId);
	return isTicketInSelectedQueues;
}

export default ShouldUpdateTicket;