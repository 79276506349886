import React from "react";
import { Link, Typography } from "@material-ui/core";

const Copyright = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			&copy; {"Copyright "}
			<Link color="inherit" href="https://faluzap.com.br">
				Faluzap
			</Link>{" "}
			2024
			{"."}
		</Typography>
	);
};

export default Copyright;