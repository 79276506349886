import api from "../../services/api";

const usePlans = () => {
    const getAll = async () => {
        const { data: { plans } } = await api.get("/plans/");
        return plans;
    }

    return {
        getAll
    }
};

export default usePlans;