import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import {
	Avatar,
	CssBaseline,
	TextField,
	Grid,
	Box,
	Typography,
	Container,
	InputAdornment,
	IconButton,
	Link,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
	FormHelperText
} from '@material-ui/core';

import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Copyright from "../../components/Copyright";
import usePlans from "../../hooks/usePlans";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

// TODO: translate information scheme
const UserSchema = Yup.object().shape({
	companyName: Yup.string()
		.min(2)
		.required("Required"),
	planId: Yup.number()
		.min(1)
		.required("Required"),
	userName: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string()
		.min(5, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	email: Yup.string()
		.email("Invalid email")
		.required("Required"),
});

const SignUp = () => {
	const classes = useStyles();
	const history = useHistory();

	const initialState = { companyName: "", planId: -1, userName: "", email: "", password: "" };
	const [showPassword, setShowPassword] = useState(false);
	const [user] = useState(initialState);
	const [plans, setPlans] = useState([]);
	const { getAll } = usePlans();

	const handleSignUp = async values => {
		try {
			await api.post("/auth/signup", values);
			toast.success(i18n.t("signup.toasts.success"));
			history.push("/signin");
		} catch (err) {
			toastError(err);
		}
	};

	const fetchPlans = async () => {
		const plans = await getAll();
		setPlans(plans);
	};

	useEffect(() => {
		fetchPlans();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlined />
				</Avatar>
				<Typography component="h1" variant="h5">
					{i18n.t("signup.title")}
				</Typography>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSignUp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, values, setFieldValue, isSubmitting }) => (
						<Form className={classes.form}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Field
										as={TextField}
										autoComplete="companyName"
										name="companyName"
										error={touched.companyName && Boolean(errors.companyName)}
										helperText={touched.companyName && errors.companyName}
										variant="outlined"
										fullWidth
										id="companyName"
										label={i18n.t("signup.form.companyName")}
										autoFocus
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl
										fullWidth
										variant="outlined"
										error={touched.planId && Boolean(errors.planId)}
									>
										<InputLabel id="plans-selection-label" >{i18n.t("signup.form.selectPlan")}</InputLabel>
										<Select
											labelId="plans-selection-label"
											id="planId"
											name="planId"
											label={i18n.t("signup.form.selectPlan")}
											value={values.planId}
											onChange={value => setFieldValue(value.target.name, value.target.value)}
										>
											<MenuItem disabled value={-1}>{i18n.t("signup.form.selectPlan")}</MenuItem>
											{plans.map((plan) => (
												<MenuItem key={plan.id} value={plan.id}>
													{plan.name} - {i18n.t("queues.title")}: {plan.queues} - {i18n.t("users.title")}: {plan.users} - {i18n.t("connections.title")}: {plan.connections} - R$ {plan.value}
												</MenuItem>
											))}
										</Select>
										<FormHelperText>{touched.planId && errors.planId}</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Field
										as={TextField}
										autoComplete="userName"
										name="userName"
										error={touched.userName && Boolean(errors.userName)}
										helperText={touched.userName && errors.userName}
										variant="outlined"
										fullWidth
										id="userName"
										label={i18n.t("signup.form.userName")}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										id="email"
										label={i18n.t("signup.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										autoComplete="email"
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										name="password"
										id="password"
										autoComplete="current-password"
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										label={i18n.t("signup.form.password")}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword((e) => !e)}
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
							</Grid>
							<ButtonWithSpinner
								loading={isSubmitting}
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								{i18n.t("signup.buttons.submit")}
							</ButtonWithSpinner>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Link
										href="#"
										variant="body2"
										component={RouterLink}
										to="/login"
									>
										{i18n.t("signup.buttons.login")}
									</Link>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
			<Box mt={5}><Copyright /></Box>
		</Container>
	);
};

export default SignUp;
