export function getUserEvent(companyId) {
  return `${companyId}-user`;
}

export function getAppMessageEvent(companyId) {
  return `${companyId}-appMessage`;
}

export function getTicketEvent(companyId) {
  return `${companyId}-ticket`;
}

export function getWhatsappSessionEvent(companyId) {
  return `${companyId}-whatsappSession`;
}

export function getContactEvent(companyId) {
  return `${companyId}-contact`;
}

export function getWhatsappEvent(companyId) {
  return `${companyId}-whatsapp`;
}

export function getQueueEvent(companyId) {
  return `${companyId}-queue`;
}

export function getQuickAnswerEvent(companyId) {
  return `${companyId}-quickAnswer`;
}

export function getSettingsEvent(companyId) {
  return `${companyId}-settings`;
}

export const StatusWhatsapp = {
  OPENING: "opening",
  QRCODE: "qrcode",
  DISCONNECTED: "disconnected",
  CONNECTED: "connected",
  TIMEOUT: "timeout",
  PAIRING: "pairing"
}

export const Profile = {
  ADMIN: "admin",
  USER: "user",
  MASTER: "master"
}

export const StatusTicket = {
  CLOSED: "closed",
  OPEN: "open",
  PENDING: "pending"
}